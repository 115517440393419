import { render, staticRenderFns } from "./LampMtap5V1ControllerTelemetryCard.vue?vue&type=template&id=0553ecfa&scoped=true&"
import script from "./LampMtap5V1ControllerTelemetryCard.vue?vue&type=script&lang=ts&"
export * from "./LampMtap5V1ControllerTelemetryCard.vue?vue&type=script&lang=ts&"
import style0 from "./LampMtap5V1ControllerTelemetryCard.vue?vue&type=style&index=0&id=0553ecfa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0553ecfa",
  null
  
)

export default component.exports